 .ficiali__partner {
    display: flex;
    flex-wrap: wrap;
    justify-self: space-between;
    margin-left: 5.6rem;
}

.ficiali__partner-heading {
    width: 100%;
    display: flex;
    text-align: left;
    margin-bottom: 2.4rem;
}

.ficiali__partner-heading h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

}

.ficiali__partner div {
    flex: 1;
    max-width: 10rem;
    margin: 1rem 5rem;
    display: flex;
    justify-self: space-between;
    align-items: center;
} 

@media screen and (max-width: 880px)
{
    .ficiali__partner {
        margin-left: -1.5rem;
    }

    .ficiali__partner-img {
        margin-top: 1rem;
    }
}

