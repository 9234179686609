
input[type=text],input[type=email], textarea {
    width: 100%; 
    padding: 12px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: horizontal;
  }
  
  label {
      color: var(--color-text);
      font-family: var(--font-family);
      font-weight: 500;
  }


  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #01945e;
  }

  textarea {
    resize: none;
  }

  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }