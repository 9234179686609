.ficiali__blog-container_article {
    width: 100%;
    /* width: 400px; */
    height: 520px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.ficiali__blog-container_article-image {
    /* width: "400px";
    height: "400px";
    objectFit: 'contain'; */
    background: var(--color-bg);
}

.ficiali__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.ficiali__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.2rem;
    height: 100%;
    text-wrap: wrap;
}

.ficiali__blog-container_article-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    text-wrap: wrap;
    
}

.ficiali__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 1rem;
}

.ficiali__blog-container_article-content p:last-child {
    cursor: pointer;
}

.card {
    margin-right: 14px
}

@media screen and (max-width: 550px) {
    .ficiali__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }

    .card {
        margin-right: 0
    }
    
}