 .ficiali__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.ficiali__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
}

.ficiali__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.ficiali__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    width: 90%;
}

.ficiali__footer-links div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 340px; */
    margin: 1rem;
}

.ficiali__footer-links_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ficiali__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin: 1rem 0 0.4rem;
}

.ficiali__footer-links_div p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

.ficiali__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.ficiali__footer-copyright p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

.ficiali__footer-social-media {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ficiali__footer-social-media p {
    margin: 0 1rem;
}

.ficiali__footer-social-media {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
}

.ficiali__footer-contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ficiali__footer-contact-details p{
    margin: 0 0.5rem;  
    font-size: 14.5px; 
}

@media screen and (max-width: 850px) {
    .ficiali__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .ficiali__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .ficiali__footer-links div {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 400px) {
    .ficiali__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
} 

