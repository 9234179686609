.ficiali__blog {
    display: flex;
    flex-direction: column;    
    margin-bottom: 40px;
}

.ficiali__blog-heading {
    width: 100%;
    display: flex;
    text-align: left;
    margin-bottom: 3rem;
}

.ficiali__blog-heading h1 {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 800;
        font-size: 34px;
        line-height: 45px;

}


@media screen and (max-width: 700px) {
    .ficiali__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .ficiali__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}